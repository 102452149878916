import React, { useEffect, useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/firestore';
import { TonConnectButton, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import { useUser } from '../context/userContext';
import { IoCheckmarkCircleSharp, IoClose } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';

const rankBoosting = [
  {
    title: "10,000 $GRB",
    capacity: 10000,
    cost: '100000000',
    price: 0.1,
    xx: '10,000'
  },
  {
    title: "30,000 $GRB",
    capacity: 30000,
    cost: '300000000',
    price: 0.3,
    xx: '30,0000'
  },
  {
    title: "50,000 $GRB",
    capacity: 50000,
    cost: '500000000',
    price: 0.5,
    xx: '10x'
  },
  {
    title: "100,000 $GRB",
    capacity: 100000,
    cost: '700000000',
    price: 0.7,
    xx: '100,000'
  },
];

const BoostRank = () => {
  const { id, balance, setBalance, tonTransactions, setTonTransactions } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [openUpgrade, setOpenUpgrade] = useState(false);
  const [selectedPower, setSelectedPower] = useState(null);
  const [showCongratsModal, setShowCongratsModal] = useState(false);
  const [congratsMessage, setCongratsMessage] = useState("");
  const [buttonText, setButtonText] = useState("Make Purchase");
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("");
  const wallet = useTonWallet();
  const [tonConnectUI] = useTonConnectUI();
  const [openBoost, setOpenBoost] = useState(false);
  const [congrats, setCongrats] = useState(false);

  useEffect(() => {
    const initializeTonConnect = async () => {
      try {
        await tonConnectUI.connectionRestored;
        setIsLoading(false);
      } catch (err) {
        console.error('TonConnect initialization error:', err);
        setMessage(`TonConnect error: ${err.message}`);
        setMessageColor("red");
        setIsLoading(false);
      }
    };
    initializeTonConnect();
  }, [tonConnectUI]);

  const transaction = (cost) => ({
    validUntil: Math.floor(Date.now() / 1000) + 300,
    messages: [
      {
        address: "UQB4U-I-4Uq7MNVb7nQvCQo_z-8GcjqCauP-FZPHy1swEgad",
        amount: cost,
      },
    ],
  });

  const handleClick = async () => {
    setButtonText("Processing...");
    setButtonDisabled(true);

    if (!selectedPower) return;

    try {
      const response = await tonConnectUI.sendTransaction(transaction(selectedPower.cost));
      console.log('Transaction sent successfully', response);

      const newMiningPower = balance + selectedPower.capacity;
      const userRef = doc(db, 'telegramUsers', id.toString());
      await updateDoc(userRef, {
        balance: newMiningPower,
        tonTransactions: tonTransactions + 1,
      });
      setBalance(newMiningPower);
      setTonTransactions(tonTransactions + 1)

      setCongratsMessage(
        <div className="w-full flex justify-center flex-col items-center space-y-3">
          <IoCheckmarkCircleSharp size={32} className="text-accent" />
          <p className="font-medium text-center">Congratulations!</p>
          <span className="font-medium text-[20px] text-[#ffffff] pt-2 pb-2 flex flex-col justify-center w-full text-center items-center space-x-1">
            <span className='flex items-center justify-center space-x-[2px] text-[18px]'>
              <img src='/dogs.webp' alt='sdfd' className='w-[15px]' />
              <span className="text-accent">+{selectedPower.capacity} $GRB</span>
            </span>
            <span>Your Purchase of {selectedPower.xx} $GRB is successful</span>
          </span>
          <p className="pb-6 text-[15px] w-full text-center">
            Perform more activities or buy more $GRB to stay ahead and claim listing giveaway bonuses!😎
          </p>
        </div>
      );

      setShowCongratsModal(true);
      setOpenUpgrade(false);
      setCongrats(true);
      setTimeout(() => {
        setCongrats(false);
      }, 3000);

      setMessage("");
      setMessageColor("green");
    } catch (err) {
      console.error('Transaction error:', err);
      setMessage("Transaction error, Make sure you have connected wallet from home page.");
      setMessageColor("red");
    } finally {
      setButtonText("Make Purchase");
      setButtonDisabled(false);
    }
  };

  const openUpgrader = (power) => {
    setSelectedPower(power);
    setOpenUpgrade(true);
    setOpenBoost(false);
  };

  const closeUpgrader = () => {
    setOpenBoost(true);
    setOpenUpgrade(false);
    setMessage("");
  }

  return (
    <>
      <button
        onClick={() => setOpenBoost(true)}
        className='bg-btn4 h-[45px] flex justify-center items-center space-x-2 font-medium w-full rounded-[8px]'>
        <img src='/prem.svg' alt='fdfvds' className='w-[14px]' />
        <span>
          Boost your Rank
        </span>
      </button>

      {openBoost && (
        <div className="fixed flex bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#303030c4] flex-col justify-end items-center">
          <div className="w-full bg-divider shadowtop rounded-tl-[40px] rounded-tr-[40px] relative flex flex-col ease-in duration-300 transition-all justify-center">
            <div className="w-full flex taskbg rounded-tl-[40px] rounded-tr-[40px] mt-[2px] justify-center relative flex-col items-center space-y-3 p-4 pt-14 pb-10">
              <button
                onClick={() => setOpenBoost(false)}
                className="flex items-center justify-center h-[32px] w-[32px] rounded-full bg-[#383838] absolute right-6 top-4 text-center font-medium text-[16px]"
              >
                <IoClose size={20} className="text-[#9995a4]" />
              </button>

              <div className="w-full flex justify-center flex-col items-center">
                <img src='/dogs.webp' alt='sdfd' className='w-[70px]' />
                <h3 className="font-semibold text-center text-[20px] pt-2 pb-4">
                  Get more {process.env.REACT_APP_PROJECT_NAME_SMALL}
                </h3>
                <div className='w-full flex flex-col space-y-2'>
                  {rankBoosting.map((power, index) => (
                    <button key={index} onClick={() => openUpgrader(power)}
                      className='w-full bg-cards py-4 px-4 rounded-[8px] flex justify-between items-center'>
                      <span className='flex flex-1 space-x-[6px] items-center'>
                        <span className='w-[24px] h-[24px] fanbg border-[#616161] border-[1px] flex justify-center rounded-full items-center text-center relative'>
                          <img src='/fan.webp' alt='dscfd' className='w-[18px] h-[18px]' />
                          <span className='absolute z-10 bg-[#3f2900] border-[1px] border-[#8b8b8b] rounded-full h-[14px] w-[14px] flex justify-center items-center'>
                            <img src='/dogs.webp' alt='sdfd' className='w-[7px]' />
                          </span>
                        </span>
                        <span className='text-[14px] font-medium'>
                          {power.title}
                        </span>
                      </span>
                      <span className='text-[13px] font-semibold text-[#2c99dd]'>
                        {power.price} <span className='text-[#2c99dd]'> TON</span>
                      </span>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {openUpgrade && selectedPower && (
        <div className="fixed flex bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#303030c4] flex-col justify-end items-center">
          <div className="w-full bg-divider shadowtop rounded-tl-[40px] rounded-tr-[40px] relative flex flex-col ease-in duration-300 transition-all justify-center">
            <div className="w-full flex taskbg rounded-tl-[40px] rounded-tr-[40px] mt-[2px] justify-center relative flex-col items-center space-y-3 p-4 pt-14 pb-10">
              <button
                onClick={closeUpgrader}
                className="flex items-center justify-center h-[32px] w-[32px] rounded-full bg-[#383838] absolute right-6 top-4 text-center font-medium text-[16px]"
              >
                <IoClose size={20} className="text-[#9995a4]" />
              </button>

              <h3 className="font-semibold text-center text-[20px] pt-2 pb-4">
                Upgrade your rank with {selectedPower.title}
              </h3>

              <div className="w-full flex flex-col space-y-4 items-center">
                <button
                  onClick={handleClick}
                  disabled={buttonDisabled}
                  className={`w-full h-[45px] rounded-[8px] bg-accent flex justify-center items-center font-medium ${buttonDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  {buttonText}
                </button>
                {message && (
                  <p className={`text-center ${messageColor}`}>
                    {message}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {showCongratsModal && (
        <div className="fixed z-50 flex items-center justify-center left-0 right-0 top-0 bottom-0 bg-[#303030c4]">
          <div className="bg-cards p-4 rounded-lg text-center">
            {congratsMessage}
            <button
              className="mt-4 bg-accent rounded-lg py-2 px-4 text-white"
              onClick={() => setShowCongratsModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default BoostRank;
