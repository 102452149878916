import React, { useState, useEffect } from 'react';
import { db } from '../firebase/firestore'; // Adjust based on your project structure
import { doc, getDoc, updateDoc, Timestamp, setDoc } from 'firebase/firestore';
import { useUser } from '../context/userContext';
import { useNavigate } from 'react-router-dom';
import './holdCoinGame.css';

const HoldCoinGame = () => {
    const { id, balance, setBalance, setError } = useUser();
    const navigate = useNavigate();
    const [holding, setHolding] = useState(false);
    const [timer, setTimer] = useState(60);
    const [rewardMessage, setRewardMessage] = useState('');
    const [gameOver, setGameOver] = useState(false);
    const [canPlay, setCanPlay] = useState(true);
    const [dailyLimitMessage, setDailyLimitMessage] = useState('');
    const [nextClaimTimer, setNextClaimTimer] = useState(0);

    useEffect(() => {
        const checkLastHoldTime = async () => {
            if (!id) return;

            const holdCoinDocRef = doc(db, 'HoldCoinTime', id);
            const holdCoinDoc = await getDoc(holdCoinDocRef);
            if (holdCoinDoc.exists()) {
                const { claimedAt } = holdCoinDoc.data();
                const now = Timestamp.now();
                const timeSinceClaim = now.seconds - claimedAt.seconds;

                if (timeSinceClaim < 86400) { // 86400 seconds = 24 hours
                    setCanPlay(false); // User has played today
                    setDailyLimitMessage("You can only play once a day.");
                    const timeLeft = 86400 - timeSinceClaim; // Time left in seconds
                    setNextClaimTimer(timeLeft);
                } else {
                    // Reset the user's claimed status if 24 hours have passed
                    await updateDoc(holdCoinDocRef, { claimed: false, claimedAt: null });
                    setCanPlay(true);
                    setDailyLimitMessage('');
                }
            } else {
                // Create user in HoldCoinTime if it doesn't exist
                await setDoc(holdCoinDocRef, { claimed: false, claimedAt: Timestamp.fromDate(new Date()) });
            }
        };

        checkLastHoldTime();
    }, [id]);

    useEffect(() => {
        let countdown;
        if (holding && timer > 0) {
            countdown = setInterval(() => {
                setTimer(prev => prev - 1);
            }, 1000);
        } else if (timer === 0) {
            handleReward(5000); // Full reward for 60 seconds
        }
        return () => clearInterval(countdown);
    }, [holding, timer]);

    useEffect(() => {
        if (nextClaimTimer > 0) {
            const interval = setInterval(() => {
                setNextClaimTimer(prev => {
                    if (prev <= 1) {
                        clearInterval(interval);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [nextClaimTimer]);

    const handleReward = async (points) => {
        if (!id) return;
        try {
            const holdCoinDocRef = doc(db, 'HoldCoinTime', id);
            const holdCoinDoc = await getDoc(holdCoinDocRef);

            if (!holdCoinDoc.exists() || !holdCoinDoc.data().claimed) {
                const userDocRef = doc(db, 'telegramUsers', id);
                const userDoc = await getDoc(userDocRef);

                if (userDoc.exists()) {
                    const newBalance = (userDoc.data().balance || 0) + points;

                    await updateDoc(userDocRef, {
                        balance: newBalance,
                    });

                    // Update HoldCoinTime to indicate that the user has claimed
                    await updateDoc(holdCoinDocRef, {
                        claimed: true,
                        claimedAt: Timestamp.fromDate(new Date()), // Store claim time
                    });

                    setBalance(newBalance);
                    setRewardMessage(`You earned: ${points} $GRB!`);
                    setGameOver(true);
                    setDailyLimitMessage(''); // Clear daily limit message if reward is claimed
                    setNextClaimTimer(86400); // Reset next claim timer to 24 hours
                }
            } else {
                setRewardMessage("You are rock lets hold it.");
                setDailyLimitMessage("You can only play once a day.");
            }
        } catch (err) {
            console.error('Error updating balance:', err);
            setError('Failed to update balance.');
        }
    };

    // Touch event handlers
    const handleTouchStart = () => {
        if (!canPlay) {
            setRewardMessage("You are rock lets hold it.");
            setDailyLimitMessage("You can only play once a day.");
            return;
        }
        setHolding(true);
        setTimer(60); // Reset timer when holding starts
        setRewardMessage('Holding...'); // Initial message
    };

    const handleTouchEnd = () => {
        setHolding(false);
        // Calculate reward based on hold time
        const reward = Math.round((60 - timer) / 60 * 5000); // Calculate reward based on time held
        handleReward(reward > 0 ? reward : 0);
        setTimer(60); // Reset timer for the next game
    };

    const handleClose = () => {
        navigate('/allgame');
    };

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hours}h ${minutes}m ${secs}s`;
    };

    return (
        <div className="hold-coin-game-container">
            <h2 className="hold-coin-game-title font-serif">Hold The Star</h2>
            <div className="hold-coin-game-message">{dailyLimitMessage}</div>
            <div
                className="hold-coin-game-star-area"
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                onTouchCancel={handleTouchEnd} // Handle case if touch is interrupted
            >
                <span className="hold-coin-game-star-emoji">⭐</span>
            </div>
            {/* Timer Display Below the Holding Area */}
            <div className="hold-coin-game-timer">{holding ? timer : ''}</div>
            <div className="hold-coin-game-next-claim-timer">
                Next Claim in: {nextClaimTimer > 0 ? formatTime(nextClaimTimer) : "You can claim again!"}
            </div>
            {gameOver && (
                <div className="hold-coin-game-alert">
                    <div className="hold-coin-game-alert-box">
                        <p>{rewardMessage}</p>
                        <button className="hold-coin-game-alert-button" onClick={handleClose}>
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default HoldCoinGame;
