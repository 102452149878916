import React, { useState, useEffect } from 'react';
import { db } from '../firebase/firestore'; // Adjust based on your project structure
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { useUser } from '../context/userContext';
import { useNavigate } from 'react-router-dom';
import './StarCatcher.css'; // For custom styles

const StarCatcher = () => {
    const { id } = useUser(); // Get user ID from context
    const navigate = useNavigate(); // Initialize navigate
    const [fallingObjects, setFallingObjects] = useState([]);
    const [score, setScore] = useState(0);
    const [dogPoints, setDogPoints] = useState(0);
    const [gameTime, setGameTime] = useState(30);
    const [gameOver, setGameOver] = useState(false);
    const [congratulationsMessage, setCongratulationsMessage] = useState('');
    const [playLimitReached, setPlayLimitReached] = useState(false); // To track if play limit is reached
    const [playCount, setPlayCount] = useState(0); // To track user's play count
  
    useEffect(() => {
        const fetchPlayCount = async () => {
            if (!id) return;

            try {
                const stardogsDocRef = doc(db, 'stardogs', id);
                const stardogsDoc = await getDoc(stardogsDocRef);
                
                if (stardogsDoc.exists()) {
                    const userPlayCount = stardogsDoc.data().playCount || 0;
                    setPlayCount(userPlayCount);
                    setPlayLimitReached(userPlayCount >= 10); // Set play limit status
                }
            } catch (err) {
                console.error('Error fetching user data:', err);
            }
        };

        fetchPlayCount();
    }, [id]);

    useEffect(() => {
        if (gameTime > 0) {
            const timer = setInterval(() => setGameTime((prev) => prev - 1), 1000);
            return () => clearInterval(timer);
        } else {
            setGameOver(true);
            updatePoints();
        }
    }, [gameTime]);

    useEffect(() => {
        if (!gameOver) {
            const interval = setInterval(() => {
                createFallingObject();
            }, 200);
            return () => clearInterval(interval);
        }
    }, [gameOver]);

    const createFallingObject = () => {
        const isStar = Math.random() < 0.8; // 80% chance of a star
        const isDog = Math.random() < 0.1; // 10% chance of a dog
        const newObject = {
            id: Math.random(),
            type: isDog ? 'dog' : (isStar ? 'star' : 'bomb'),
            position: Math.random() * 90,
            startY: -50,
            clicked: false // Initial state for click animation
        };

        setFallingObjects((prev) => [...prev, newObject]);

        setTimeout(() => {
            setFallingObjects((prev) => prev.filter((obj) => obj.id !== newObject.id));
        }, 4000);
    };

    const handleClickObject = (object) => {
        // Update clicked object to show click animation
        setFallingObjects((prev) =>
            prev.map((obj) => {
                if (obj.id === object.id) {
                    return { ...obj, clicked: true }; // Trigger the click animation
                }
                return obj;
            })
        );

        setTimeout(() => {
            // Remove the click effect after a brief delay
            setFallingObjects((prev) =>
                prev.map((obj) => {
                    if (obj.id === object.id) {
                        return { ...obj, clicked: false }; // Reset the click animation
                    }
                    return obj;
                })
            );
        }, 300); // Duration of the animation

        // Scoring logic
        if (object.type === 'star') {
            setScore((prev) => prev + 1);
        } else if (object.type === 'dog') {
            const dogPointsIncreased = 2;
            setDogPoints((prev) => prev + dogPointsIncreased);
            setScore((prev) => prev + dogPointsIncreased);
            const sound = new Audio('/dog-bark.mp3');
            sound.play();
        } else if (object.type === 'bomb') {
            triggerBombEffect();
            setScore(0);
            setDogPoints(0);
        }

        // Remove the clicked object from the screen
        setFallingObjects((prev) => prev.filter((obj) => obj.id !== object.id));
    };

    const triggerBombEffect = () => {
        if (navigator.vibrate) {
            navigator.vibrate(300);
        }
        document.body.classList.add('bomb-blast');
        setTimeout(() => document.body.classList.remove('bomb-blast'), 300);
    };

    const updatePoints = async () => {
        if (!id) return;

        try {
            const stardogsDocRef = doc(db, 'stardogs', id);
            const stardogsDoc = await getDoc(stardogsDocRef);

            if (stardogsDoc.exists()) {
                const newStarPoints = (stardogsDoc.data().starPoints || 0) + score;
                const newDogPoints = (stardogsDoc.data().dogPoints || 0) + dogPoints;

                await updateDoc(stardogsDocRef, {
                    starPoints: newStarPoints,
                    dogPoints: newDogPoints,
                });
            } else {
                await setDoc(stardogsDocRef, {
                    starPoints: score,
                    dogPoints: dogPoints,
                });
            }

            setCongratulationsMessage(`Congratulations! You have claimed ${score.toFixed(2)} stars and ${dogPoints.toFixed(2)} dog points!`);
        } catch (err) {
            console.error('Error updating points:', err);
        }
    };

    const handleClose = () => {
        navigate('/gamehome');
    };

    // Render game over message
    const renderGameOverMessage = () => (
        <div className="fixed z-50 flex items-center justify-center left-0 right-0 top-0 bottom-0 bg-[#303030c4]">
            <div className="bg-cards p-4 rounded-lg text-center">
                <p>{congratulationsMessage}</p>
                {playLimitReached ? (
                    <p className="text-red-500">You have reached the play limit !</p>
                ) : (
                    <button
                        className="mt-4 bg-accent rounded-lg py-2 px-4 text-white"
                        onClick={() => window.location.reload()}
                    >
                        Play Again
                    </button>
                )}
                
                <button
                    className="mt-2 bg-red-500 rounded-lg py-2 px-4 text-white"
                    onClick={handleClose}
                >
                    Close
                </button>
            </div>
        </div>
    );

    return (
        <div className="star-catcher-container">
            <div className="game-info">
                <div className="score">Girbo: {score.toFixed(2)}</div>
                <div className="dog-points">DGS: {dogPoints.toFixed(2)}</div>
                <div className="time-left">Time Left: {gameTime}s</div>
            </div>

            {gameOver ? (
                renderGameOverMessage()
            ) : (
                <div className="falling-objects-container">
                    {fallingObjects.map((object) => (
                        <div
                            key={object.id}
                            className={`falling-object ${object.type} ${object.clicked ? 'click-animation' : ''}`}
                            style={{
                                left: `${object.position}%`,
                                top: `${object.startY}px`,
                                animation: `fall 2s linear`,
                            }}
                            onClick={() => handleClickObject(object)}
                            data-id={object.id}
                        >
                            {object.type === 'star' ? (
                                <img src="/star.png" alt="Star" className="falling-star-image" />
                            ) : object.type === 'dog' ? (
                                <img src="/dog.png" alt="Dog" className="falling-dog-image" />
                            ) : (
                                '💣'
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default StarCatcher;