import React, { useEffect, useState } from 'react';
import { MdArrowRightAlt } from 'react-icons/md';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/firestore';
import { TonConnectButton, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import { useUser } from '../context/userContext';
import { IoCheckmarkCircleSharp, IoClose } from 'react-icons/io5';

const miningPowers = [
  {
    title: "3x mining +1200 PPH",
    capacity: 1200,
    cost: '200000000',
    price: 0.2,
    xx: '3x'
  },
  {
    title: "5x mining +2000 PPH",
    capacity: 2000,
    cost: '300000000',
    price: 0.3,
    xx: '5x'
  },
  {
    title: "10x mining +4000 PPH",
    capacity: 4000,
    cost: '500000000',
    price: 0.5,
    xx: '10x'
  },
  {
    title: "15x mining +6000 PHH",
    capacity: 6000,
    cost: '700000000',
    price: 0.7,
    xx: '15x'
  },
];

const BoostFarm = () => {
  const { id, setMiningPower } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [openUpgrade, setOpenUpgrade] = useState(false);
  const [selectedPower, setSelectedPower] = useState(null);
  const [showCongratsModal, setShowCongratsModal] = useState(false);
  const [congratsMessage, setCongratsMessage] = useState("");
  const [buttonText, setButtonText] = useState("Make Purchase");
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("");
  const wallet = useTonWallet();
  const [tonConnectUI] = useTonConnectUI();
  const [openBoost, setOpenBoost] = useState(false);
  const [congrats, setCongrats] = useState(false);

  useEffect(() => {
    const initializeTonConnect = async () => {
      try {
        await tonConnectUI.connectionRestored;
        setIsLoading(false);
      } catch (err) {
        console.error('TonConnect initialization error:', err);
        setMessage(`TonConnect error: ${err.message}`);
        setMessageColor("red");
        setIsLoading(false);
      }
    };
    initializeTonConnect();
  }, [tonConnectUI]);

  const transaction = (cost) => ({
    validUntil: Math.floor(Date.now() / 1000) + 300,
    messages: [
      {
        address: "UQB4U-I-4Uq7MNVb7nQvCQo_z-8GcjqCauP-FZPHy1swEgad",
        amount: cost,
      },
    ],
  });

  const handleClick = async () => {
    setButtonText("Processing...");
    setButtonDisabled(true);
    
    if (!selectedPower) return;

    try {
      const response = await tonConnectUI.sendTransaction(transaction(selectedPower.cost));
      console.log('Transaction sent successfully', response);

      const newMiningPower = selectedPower.capacity;
      const userRef = doc(db, 'telegramUsers', id.toString());
      await updateDoc(userRef, {
        miningPower: newMiningPower,
      });
      setMiningPower(newMiningPower);

      setCongratsMessage(
        <div className="w-full flex justify-center flex-col items-center space-y-3">
          <IoCheckmarkCircleSharp size={32} className="text-accent" />
          <p className="font-medium text-center">Congratulations!</p>
          <span className="font-medium text-[20px] text-[#ffffff] pt-2 pb-2 flex flex-col justify-center w-full text-center items-center space-x-1">
            <span className='flex items-center justify-center space-x-[2px] text-[18px]'>
              <span className='w-[20px] h-[20px] fanbg border-[#616161] border-[1px] flex justify-center rounded-full items-center text-center relative'>
                <img src='/fan.webp' alt='dscfd' className='w-[16px] h-[16px]'/>
                <span className='absolute z-10 bg-[#3f2900] border-[1px] border-[#8b8b8b] rounded-full h-[12px] w-[14px] flex justify-center items-center'>
                  <img src='/dogs.webp' alt='sdfd' className='w-[7px]'/>
                </span>
              </span>
              <span className="text-accent">+{selectedPower.capacity} PPH</span>
            </span>
            <span>Mining Rig Upgraded to {selectedPower.xx} Proft Per Hour</span>
          </span>
          <p className="pb-6 text-[15px] w-full text-center">
            Your new mining capacity is now activated and will start working for you! Good luck miner! 😎
          </p>
        </div>
      );
      setShowCongratsModal(true);
      setOpenUpgrade(false);
      setCongrats(true);
      setTimeout(() => {
        setCongrats(false);
      }, 3000);

      setMessage("");
      setMessageColor("green");
    } catch (err) {
      console.error('Transaction error:', err);
      setMessage("Transaction error, Make sure you have connected wallet from home page");
      setMessageColor("red");
    } finally {
      setButtonText("Make Purchase");
      setButtonDisabled(false);
    }
  };

  const openUpgrader = (power) => {
    setSelectedPower(power);
    setOpenUpgrade(true);
    setOpenBoost(false);
  };

  const closeUpgrader = () => {
    setOpenBoost(true);
    setOpenUpgrade(false);
    setMessage("");
  };

  return (
    <>
      <div className="w-full flex items-center justify-center px-3 pt-16">
        <button 
          onClick={() => setOpenBoost(true)}
          className="w-full bg-btn4 text-[#000] py-3 px-4 text-center font-semibold rounded-[8px] flex items-center justify-center space-x-1">
          <span> Boost Mining</span>
          <MdArrowRightAlt size={16} className="mt-[2px]"/>
        </button>
      </div>

      {openBoost && (
        <div className="fixed flex bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#303030c4] flex-col justify-end items-center">
          <div className="w-full bg-divider shadowtop rounded-tl-[40px] rounded-tr-[40px] relative flex flex-col ease-in duration-300 transition-all justify-center">
            <div className="w-full flex taskbg rounded-tl-[40px] rounded-tr-[40px] mt-[2px] justify-center relative flex-col items-center space-y-3 p-4 pt-14 pb-10">
              <button
                onClick={() => setOpenBoost(false)}
                className="flex items-center justify-center h-[32px] w-[32px] rounded-full bg-[#383838] absolute right-6 top-4 text-center font-medium text-[16px]"
              >
                <IoClose size={20} className="text-[#9995a4]" />
              </button>

              <div className="w-full flex justify-center flex-col items-center">
                <div className='w-[60px] h-[60px] fanbg border-[#616161] border-[2px] flex justify-center rounded-full items-center text-center relative'>
                  <img src='/fan.webp' alt='dscfd' className='w-[45px] h-[45px]'/>
                  <div className='absolute z-10 bg-[#3f2900] border-[1px] border-[#8b8b8b] rounded-full h-[24px] w-[24px] flex justify-center items-center'>
                    <img src='/dogs.webp' alt='sdfd' className='w-[10px]'/>
                  </div>
                </div>
                <h3 className="font-semibold text-center text-[20px] pt-2 pb-4">
                  Increase mining power
                </h3>
                <div className='w-full flex flex-col space-y-2'>
                  {miningPowers.map((power, index) => (
                    <button key={index} onClick={() => openUpgrader(power)}
                      className='w-full bg-cards py-4 px-4 rounded-[8px] flex justify-between items-center'>
                      <span className='flex flex-1 space-x-[6px] items-center'>
                        <span className='w-[24px] h-[24px] fanbg border-[#616161] border-[1px] flex justify-center rounded-full items-center text-center relative'>
                          <img src='/fan.webp' alt='dscfd' className='w-[18px] h-[18px]'/>
                          <span className='absolute z-10 bg-[#3f2900] border-[1px] border-[#8b8b8b] rounded-full h-[12px] w-[14px] flex justify-center items-center'>
                            <img src='/dogs.webp' alt='sdfd' className='w-[7px]'/>
                          </span>
                        </span>
                        <span className='font-medium text-left text-[16px]'>{power.title}</span>
                      </span>
                      <span className='font-medium text-right text-[16px]'>
                        {power.price} TON
                      </span>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {openUpgrade && (
        <div className="fixed flex bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#303030c4] flex-col justify-end items-center">
          <div className="w-full bg-divider shadowtop rounded-tl-[40px] rounded-tr-[40px] relative flex flex-col ease-in duration-300 transition-all justify-center">
            <div className="w-full flex taskbg rounded-tl-[40px] rounded-tr-[40px] mt-[2px] justify-center relative flex-col items-center space-y-3 p-4 pt-14 pb-10">
              <button
                onClick={() => closeUpgrader()}
                className="flex items-center justify-center h-[32px] w-[32px] rounded-full bg-[#383838] absolute right-6 top-4 text-center font-medium text-[16px]"
              >
                <IoClose size={20} className="text-[#9995a4]" />
              </button>

              <div className="w-full flex justify-center flex-col items-center">
                <div className='w-[60px] h-[60px] fanbg border-[#616161] border-[2px] flex justify-center rounded-full items-center text-center relative'>
                  <img src='/fan.webp' alt='dscfd' className='w-[45px] h-[45px]'/>
                  <div className='absolute z-10 bg-[#3f2900] border-[1px] border-[#8b8b8b] rounded-full h-[24px] w-[24px] flex justify-center items-center'>
                    <img src='/dogs.webp' alt='sdfd' className='w-[10px]'/>
                  </div>
                </div>
                <h3 className="font-semibold text-center text-[20px] pt-2 pb-4">
                  Upgrade mining power to {selectedPower?.xx}
                </h3>
                <div className='w-full flex justify-between py-2 px-4'>
                  <span className='text-white'>{selectedPower?.title}</span>
                  <span className='text-white'>{selectedPower?.price} TON</span>
                </div>

                <button
                  onClick={handleClick}
                  disabled={buttonDisabled}
                  className={`w-full py-4 px-4 rounded-[8px] flex justify-center items-center ${buttonDisabled ? 'bg-gray-400' : 'bg-btn4'} text-black font-medium`}
                >
                  {buttonText}
                </button>

                {message && (
                  <span className="text-[14px]" style={{ color: messageColor }}>{message}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {showCongratsModal && (
        <div className="fixed flex bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#303030c4] flex-col justify-center items-center">
          <div className="w-full bg-[#1f1f1f] shadowtop rounded-[20px] relative flex flex-col ease-in duration-300 transition-all justify-center p-6">
            <button
              onClick={() => setShowCongratsModal(false)} // Close button functionality
              className="absolute top-4 right-4 text-white">
              <IoClose size={24} />
            </button>
            {congratsMessage}
          </div>
        </div>
      )}
    </>
  );
};

export default BoostFarm;
